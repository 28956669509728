/* generic */
*{
	box-sizing: border-box;
}
html,body,ul{
	margin:0;
	padding:0;
}
html,body,button{
	font-size:18px;
}
body{
  background: $genBg;
	color:$color;
}
[dir=rtl] body{
	direction: rtl;
}
body,button{
	/* font-family: 'OpenSansHebrew-Regular'; */
  font-family: "Open Sans", sans-serif;
}
[lang=he] body,[lang=he] button{
	/* font-family: 'OpenSansHebrew-Regular'; */
  font-family: FbExtazaRegular;
}

table{
  border-collapse: collapse;
  width:100%;
}
th,td{
  padding:4px 8px;
}
th,tr:nth-child(even){
  background-color: $genBg;
}
button,.pointer,.menu li{
	cursor: pointer;
}
h1,h2,h3,h4,h5,h6,p{
	margin:0;
	margin-bottom:.5rem;
}
h1{
	font-size:2.5rem;
}
@for $i from 2 through 6 {
	h#{$i}{
		font-size:#{2-((($i)-2)/4)}rem;
	}
}
.underline {
  text-decoration: underline;
}
.fa{
	font-size: $fontSize;
}
html[dir=rtl] body{
	direction: rtl;
}
ul,li{
	display:flex;
	align-items: center;
	list-style-type: none;
}
button{
  outline:0;
  border:$borderTransparent;
}
i,.menu a,button a,.dropdown a{
	color:white;
}
a{
  text-decoration: none;
}