/* Desktop */
@media (min-width:1190px){
	$newImages:$images+"/new_chart_icons/desktop";

	section.pad{
		@include genPad(10);
	}
	.non-lg{
		display:none;
	}
	.only-lg{
		display:block;
	}
	.non-app{
		display: block;
	}
	.only-app{
		display:none;
	}

	header{
		padding: 5px 40px;
		.menu{
			&.user-links{
				span,a{
					font-size:inherit;
				}
			}
			&>ul>li{
				span::after,a::after{
					height: 15px;
				}
			}
			&.lang{
				.dropdown{
					left:auto;
				}
			}
		}
	}
	header>div,[dir=rtl] .menu,.menu{
		margin-left:12px;
		margin-right:12px;
	}
	.home-bg{
		background-size: auto calc(100vh - #{$headerH} - #{$topNotifH});
		height: calc(100vh - #{$headerH} - #{$topNotifH});
	}

	/* home */
	.home{
		.content{
			height: calc(100vh - #{$headerH} - #{$topNotifH});
			// padding-top: 40px;
			padding-top:0;
			justify-content: space-evenly;

			img{
				display: block;

				&.sitting-jumbee{
					display:none;
				}
				&.keyboard{
					max-width: 25%;
				}
			}
			h2{
				// text-align: center;
				display: block;
				margin:0;
			}
			.btns{
				margin-top:40px;
				margin-bottom:-20px;
				flex-direction: row;
				
				button{
					margin:0 10px;
				}
			}
		}
	} 
	/* static */
	.static .content{
		max-width: 80%;
	}

	/* filter */
	.filter .close{
		top: 140px;
		left: 24%;
		right: inherit;
		bottom: inherit;
	}

	/* modal-form */
	.modal-form{
		.bg-blue{
			padding-top:80px;
			height:calc(100vh - #{$headerH});
		}
		.modal{
			width:40%;
			.content {
				min-height:300px;
				display:flex;
				flex-direction: column;
				justify-content: space-between;

				.form{
					margin-top:40px;
					// margin-bottom:auto;
	
					li{
						// margin-bottom:20px;
					}
				}
				.regi-btns{
					margin-top:auto;
				}
			}
		}
		.close{
			margin-right:-43%;
		}
	} 
	
	/* store */
	.footer{
		width: 100%;
    left: 0;
		bottom: 0;
		padding: 12px 0;
    border-radius: inherit;
	}
	.store{

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&.pad{
			padding:20px;
			padding-bottom:60px;
		}
		.filter-icon{
			right: 15%;
			bottom: 40px;
		}
	}
	.product{
		width:49%;
		margin: 0;
    	margin-bottom: 15px;

		.info{
			.btns{
				button{
					padding:3px 40px;
				}
			}
		}
	}
	 

	/* product-show */
	.product-show{
		&.pad{
			@include genPad(20);
		}
		.game-details .top-wrap{
			width:60%;
			.top-area-with-btn .texts>.stars-cont{
				margin-top:20px;
				flex-direction: row;
			}
		}
		
	} 
	.cart{
		.top-wrap{
			@include rtl(margin-left,margin-right,-50px);
		}
		&.pad{
			@include genPad(20);
			margin-bottom: 80px;
		}
		.product{
			.info{
				.btns{
					.empty{
						margin:0 20px;
					}
				}
			}
		}
		.above-footer{
			// @include genPad(20);
			padding:10px 20%;
		}
	}
	
	/* charts */
	.charts{
		flex-direction: column;
		&.pad{
			@include genPad(20);
		}
		.btns{
			min-width:auto;
			margin-bottom:0px;
			button{
				font-size:$fontSize;
				padding:5px 24px;
				border-radius: 15px;
			}
		}
		.main{
			padding: 40px;
			padding-top:0;

			

			p{
				font-size:$fontSize;
			}
			.stats{
				width:80%;
				margin:40px auto;

				h4{
					font-size:18px;
				}

				.result,.game{
					height:170px;
					margin:20px auto;
				}
				.game{

					p,h4,button{
						font-size:14px;
					}
					h4{
						margin-bottom:0.2rem;
					}
					img{
						max-height:80px;
						width:auto;
					}
					button{
						margin-top:10px;
						padding:3px 30px;
					}
					&.has::before{
						@include wh(30);
						right: calc(50% - 15px);
					}
				}
			}
		}

		.btns{
			flex-direction:row;

			button{
				margin-top:auto;

				&.filter{
					@include rtl(margin-left,margin-right,auto !important,0 !important);
				}
			}
		}
	}

	.charts2{
		max-width:1400px;
		margin-inline: auto;
		padding:42px 21px;
		font-size:15px;

		.box{
			padding: 20px;
		}

		.img{
			width: 34px;
			height: 31px;
			margin-inline-end: 11px;
			margin-block-start: 2px;

			&.mobile-screen-time{
				background-image:url(#{$newImages}/screen-time.png);
			}
			&.mobile-shopping-cart{
				background-image:url(#{$newImages}/shopping-cart.png);
			}
			&.mobile-checkbox-unactive{
				background-image:url(#{$newImages}/checkbox-unactive.png);
				width:24px;
				height:24px;
			}
			&.mobile-kid{
				background-image:url(#{$newImages}/kid.png);
			}
			&.mobile-sad{
				background-image:url(#{$newImages}/sad.png);
				width:14px;
				height:14px;
				margin:0;
			}
			&.mobile-checkbox-active{
				background-image:url(#{$newImages}/checkbox-active.png);
				width:24px;
				height:24px;
				margin-block-start: 0;
			}
			&.faces{
				background-image:url(#{$newImages}/success-failure.png);
			}
			&.medal-tab{
				width:19px;
				height:27px;
				background-image:url(#{$newImages}/medal-unactive.png);
				&.active{
					background-image:url(#{$newImages}/medal-active.png);
				}
	
			}
			&.arrow-side{
				background-image:url(#{$newImages}/arrow-rubric.png);
				width:6px;
				height:10px;
				margin-inline-start: 20px;
				margin-block-start: 0;
			}
			&.flame{
				background-image:url(#{$newImages}/top-games.png);
				width:20px;
				height:30px;
			}
			&.flame-tab{
				width:19px;
				height:28px;
				background-image:url(#{$newImages}/most-played-unactive.png);
				&.active{
					background-image:url(#{$newImages}/most-played-active.png);
				}
			}
			&.warning{
				background-image:url(#{$newImages}/warning.png);
				width:17px;
				height:15px;
				margin-block-start: 0;
				margin-block-end: 2px;
				margin-inline-end: 6px;
			}
			&.info{
				background-image:url(#{$newImages}/info.png);
				width:14px;
				height:14px;
				margin-inline-start: 8px;
				margin-block-start: 12px;
			}
		}
		.success-img{
			background-image:url(#{$newImages}/success.png);
			min-width: 25px;
			width: 25px;
			height: 25px;
		}
		.failure-img{
			background-image:url(#{$newImages}/failure.png);
			min-width: 25px;
			width: 25px;
			height: 25px;
		}

		.title{
			font-size: 22px;
		}

		.webee{
			display: block;
			position: absolute;
			z-index: 1;
			background-image:url(#{$newImages}/webee.png);
			width:84px;
			height:119px;
			margin-inline-start: calc(min(62%,870px));
			// margin-inline-start: 62%;
			margin-block-start: 340px;
		}

		.first-row{
			flex-wrap: nowrap;

			&>:first-child{
				margin-inline-end: 0;
			}
			&>:last-child{
				margin-block-start: 0;
			}

			&>div.box{	//box
				width: 32%;
				min-width: 32%;
				height:183px;
				
				&.top-game{
					width:inherit;
					height:183px;
				}

				.daily-avg-txt{
					margin-block-start: 32px;
				}
				.time-and-perc{
					flex-direction: row;
					justify-content: space-between;
					align-items: flex-end;
					margin-block-start: 10px;

					&>div{	//row
						
						&.time-txt{
							margin-block-start: 0;
							line-height: 44px;
							height: 44px;
							font-size: 21px;
			
							div:first-child{
								font-size:55px;
							}
						}
						&.perc-row{
							margin-block-start: 0;
							font-size:17px;
							
							div:last-child{
								margin-inline-start: 8px;
							}
						}
					}
				}
				.improv-perc-row{
					margin-block-start: 32px;
	
					div:last-child{
						margin-inline-start: 7px;
						font-size: 17px;
					}
				}
				.perc-block{
					margin-block-start: 18px;
					font-size: 18.5px;
					height:29px;
	
					div{						
						&:first-child{
							@include rtlSameAttr(border-radius,14px 0 0 14px,0 14px 14px 0);
						}
						&:last-child{
							@include rtlSameAttr(border-radius,0 14px 14px 0,14px 0 0 14px);
						}
					}
					
				}
				.details{
					width: 82px;
					height: 26px;
					border-radius: 12.5px;
					margin-block-start: 2px;
					font-size:11.5px;
				}
				.game{
					margin-block-start: 25px;
	
					img{
						width: 150px;
						height: 84px;
						object-fit: contain;
						border-radius: 4px;
					}
	
					&>div{
						margin-inline-start: 18px;
						padding:4px 0;
						
						&>div{
							font-size:17px;
							
							&:last-child{
								align-items: baseline;
							}
						}

	
	
					}
				}
			}
		}

		.switch{
			height:fit-content;
			max-width: none;
			margin-block-end: 0;
			font-size: 18px;
			color: #7c7c7c;
			background-color: #e8e8e8;
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
			border-radius: 17px;
			padding: 0;
			margin-block-end: -28px;
			
			&>div{
				padding-block-start: 12px;
				padding-block-end: 40px;
				cursor: pointer;

				&.active{
					border-radius: inherit;
				}
			}
		}
		
		.box{
			&.games-row{		
				padding-block-start: 40px;	
				font-size: 16.5px;	
				
				&>div{	//game box
					margin-block-end:20px;
					padding: 14px;
					padding-block-end: 10px;
					width:32.5%;
					height:70px;
					
	
					&>div:first-child{
						// width:12px;
						margin-inline-end: 8px;
					}
	
					&>div:nth-child(3){
						font-size: 16.5px;
					}
	
					img{
						width:76px;
						height:42px;
						margin-inline-end: 14px;
					}
	
					
				}
				
			}
			&.skills-row{
				padding-block-start: 40px;
				
				&>div{	//game box
					margin-block-end:21px;
					padding: 5px 20px;
					width:30%;
					height:43px;
	
					&.unplayed{
						padding:9px 14px;
					}
	
					&>div:first-child{
						font-size: 16.5px;
					}
					
				}			
			}
		}

		.store-container-row{
			margin-block-start: 53px;

			.box{
				padding:24px 34px;
			}
			
			.title{
				margin-inline-start: 7px;

				.img{
					margin-inline-end: 16px;
				}
			}
			.skills{
				margin-block-start: 65px;
				margin-inline-start: 31px;
	
				&>div{
					width:25%;
	
					.img{
						margin-inline-end: 13px;
						margin-block-start: 0;
					}
					&>div:last-child{
						font-size: 19.5px;
						// line-height: 100%;
					}
				}
	
	
			}
			.store-container-part{
				margin-block-start: 16px;
			}
	
		}

		.overlay{
			margin-block-start: 61px;

			.webee-popup{
				
				display:block;
				background-image:url(#{$newImages}/webee-popup.png);
				position: fixed;
				left: calc(50vw - 439px - 74px); 
				top:595px;
				width: 106px;
  				height: 210px;
			}

			.x-close{
				// background-image:url(#{$newImages}/x_.png);
				// width: 41px;
				// height: 41px;
				// min-height: 41px;
				// align-self: flex-end;
				// // margin-block-start: 157px;
				// margin-block-end: 12px;
				// margin-inline-end: calc(50vw - 247px - 3px - 41px);
				right: calc(50vw - 247px - 10px - 41px);
				
				&.gameSkill{
					right: calc(50vw - 439px - 15px - 20px);
				}
			}

			.box{
				width:494px;

				.title{
					margin-block-start: 42px;
					font-size: 32px;
				}
	
				&>:nth-child(2){
					margin-block-start: 27px;
					font-size: 23px;
				}

				.amazon-popup-image{
					margin-block-start: -40px;
					// background-image:url(#{$newImages}/amazon-popup-image.png);
					width: 494px;
					height: 361px;
				}

				.btns{
					margin-block-start: -50px;
					margin-block-end: 26px;
					
					&>div{
						border-radius: 20px;
						font-size: 22px;
						font-weight: bold;
						width:179px;
						height:41px;
	
						
					}
				}

				&.gameSkill{
					margin:20px auto 0;
					width: 879px;
					max-width: inherit;
					padding:34px 45px;
					font-size: 16px;

					&>:first-child{
						display: flex;
					}

					
					// .description{
					// 	display:inline;
					// }
					
					.name{
						font-size: 22px;
						margin-block-end: 23px;
						order:-1;
						
						span{
							display: none;
						}
					}
	
					.gameImg{
						order:-2;
						margin-inline-end: 25px;

						img{
							max-height: 176px;
						}
					}
	
					.graphTitle{
						display: inline;
						font-size: 18px;
						margin-block-start: 46px;
						margin-block-end: 36px;
					}
				}
			}
		}

	}

	.arc-score{

		div{
			font-size: 16px;
			font-weight: bold;
			margin-block-end: -2px;
		}

		svg{
			transform: scale(1.3);
		}
	}

	.checkout{
		&.pad{
			@include genPad(30);
		}
		.top-wrap{
			margin:0 -46px 0;
		}
		.payment-bottom,.payment-form{
			// width:50%;
			margin:20px auto;
		}
		.payment-form{
			display: flex;
			flex-direction: row;
			max-height:inherit;
			// height:auto;
			margin-bottom:10px;

			.wrap-div-sp{
				&>div{
					p.error{
						position:inherit;
						margin-top:0;
					}
				}
			}

			&>div{
				width:100%;
				label{
					min-width:30%;
				}
			}
			.exp-cont{
				@include rtlSameAttr(margin,0 0 14px,0 0 14px);

				order:1;
				.exp{
					select{
						width:40%;
						
					}
				}
			}
			
			.payment-cont{
				margin-top:auto;
				@include rtl(margin-left,margin-right,0);
				
				h2{
					font-size:24px;
				}
				button{
					width:100%;
					// border-radius: 20px;

					padding:10px 0;
					font-size: 18px;
				}
			}
		}
		.divider{
			margin-top:10px;
			padding-bottom: 10px;
		}
		.payment-bottom{
			display: block;
			font-size:14px;
			margin-top:0;
			.bottom-text{
				// margin:10px -40% 0;
			}
			.img-div{
				img{
					margin:0 10px;
				}
			}
		}
	}

	.circChart{
		div{
			@include wh(150);
			padding:20px;

			p{
				font-size:$fontSize;
			}
		}
		svg{
			@include wh(180);
		}
	}

	.coupon-cont{
		align-items: flex-start;
		min-height:auto;
		margin-bottom:14px;
		.coupon{
			width:100%;
			&>div{
				justify-content: space-between;
			}
		}
		
	}
}
