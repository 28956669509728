// vars
$color:#6f6f6f;
$linkColor:white;
$genBg:#f6f6f6;
$fontSize:18px;
$borderTransparent:1px transparent solid;
$images:"assets/images";
$checkedColor:#757575;
$langs:he,
ru,
kr,
ar,
ro,
cn,
en,
tr;
$darkerBlue:#1e457a;
$blueBg:#3063a8;
$darkRed:#dd1738;
$titleRed:#db1d3d;
$error:#dc1d3d;
$success:#3365a6;
$headerHxs:102px;
$headerH:61px;
$topNotifH:32px;

%contentBox {
  width: 100%;
  margin: 0 auto 20px;
  border-radius: 10px;
  text-align: center;
  padding: 40px;
  box-shadow: 0px 0px 30px #d9d9d9;
  background: white;
}

%btnLink {
  transition: 0.5s;
  background: $blueBg;
  color: $linkColor;
  border-radius: 30px;
  padding: 3px 30px;
  font-weight: bold;
  font-size: $fontSize;
  border: $borderTransparent;
}

%btnMargin {
  margin: 10px auto;
}

%genBtnCls {
  @extend %btnLink;
  border-radius: 20px;
  padding: 7px 35px;
  background: transparent;

  &:hover {
    color: $darkRed !important;
    background: white !important;
    border: 1px $darkRed solid !important;
  }
}

%imgDiv{
  background-repeat: no-repeat;
	background-size: cover;	
}

%flexAlignCenter {
  display: flex;
  align-items: center;
}

%flexAllCenter {
  @extend %flexAlignCenter;
  justify-content: center;
}

@mixin flexAllCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

%bgCenter {
  background-repeat: no-repeat;
  background-position: center;
}

@mixin wh($v) {
  width:#{$v}px;
  height:#{$v}px;
}

%circ {
  border-radius: 50%;
}

@mixin rtl($attr, $opAttr, $value, $resetValue:0) {
  #{$attr}: $value;

  [dir=rtl] & {
    #{$attr}: $resetValue;
    #{$opAttr}: $value;
  }
}

@mixin rtlSameAttr($attr, $value, $rtlValue) {
  #{$attr}: $value;

  [dir=rtl] & {
    #{$attr}: $rtlValue;
  }
}

@mixin genPad($perc) {
  padding:20px #{$perc+"%"};
}

// imports
// @import "/style/fonts.scss";
// @import "/style/generic.scss";
// @import "/style/xs.scss";
// @import "/style/md.scss";
// @import "/style/lg.scss";
@import "style/fonts";
@import "style/generic";
@import "style/xs";
@import "style/md";
@import "style/lg";
@import "style/stripe";








/* EXPERIMENTS!!!!!! */


/* material input */
.MatInp {
  text-align: start;
}

.MatInp label {
  display: block;
  color: blue;
  margin: 16px 6px -16px;
  height: 16px;
  transition: all 0.2s;
  font-size: 1rem;
  opacity: 0.6;
}

.MatInp input {
  border-width: 0;
  border-bottom-width: 1px;
  outline: none;
  background: transparent;
}

.MatInp input:focus {
  border-bottom-color: blue;
}

.MatInp:focus-within label,
.MatInp.hasValue label {
  margin: 0;
  font-size: 0.8rem;
}


/* tooltip */
.drag-hint {
  position: relative;
  display: inline-block;
}

.drag-hint>.popup {
  display: none;
}

.drag-hint:hover>.popup {
  display: inline;
}

.popup {
  position: absolute;
  top: -37px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 100000;
}

.popup span {
  background: black;
  border-radius: 10px;
  padding: 10px;
  color: white;
  line-height: 20px;
  margin-top: -40px;
}

.popup span::after {
  content: '';
  background: black;
  position: absolute;
  margin-top: 22px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  left: 50%;
  margin-left: -9px;
}

/* mouse click ripple */
.expand {
  position: absolute;
  border: 2px blue solid;
  border-radius: 50%;
  animation: divExp 0.5s forwards;
}

@keyframes divExp {
  from {
    opacity: 1;
    width: 1px;
    height: 1px;
    margin-left: 0;
    margin-top: 0;
  }

  to {
    opacity: 0;
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px;
  }
}





